.cell-status {
  padding: 1px 8px;
  border-radius: 6px;
}

.cell-green {
  background-color: #54d62c29;
  color: #229a16;
}

.cell-yellow {
  background-color: #ffc10729;
  color: #b78103;
}
